<template>
  <div class="is-flex is-justify-content-center">
    <div class="my-6 page-container">
      <img class="logo" src="../../assets/images/logo_icon.svg" style="margin-bottom: 1.875rem" alt="logo" />
      <!-- <img class="logo" src="../../assets/images/xmas_logo.png" style="margin-bottom: 1.875rem" alt="logo" /> -->
      <h2 class="is-size-3 has-text-white has-text-weight-semibold" style="margin-bottom: 1.5rem">Welcome to MyArena</h2>

      <form @submit.prevent>
        <div class="invalid-feedback" v-show="error.username">
          {{ error.username }}
        </div>
        <div class="invalid-feedback" v-show="error.password">
          {{ error.password }}
        </div>
        <div class="control">
          <input
            v-model="form.username"
            @keyup="error.username = null"
            type="text"
            class="main-input mb-4"
            :class="{ warning: error.username != null }"
            placeholder="Nome de Utilizador"
            required
          />
        </div>
        <div class="control">
          <input
            v-model="form.password"
            @keyup="error.password = null"
            type="password"
            class="main-input mb-4"
            :class="{ warning: error.password != null }"
            placeholder="Password"
            required
          />
        </div>
        <div class="field">
          <button @click="doLogin" :disabled="disableLogin" class="has-text-white main-btn mb-5">Entrar</button>
        </div>
      </form>
      <router-link to="/recover/password" class="reset-link">Recuperar Password?</router-link>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import UserService from "@/services/user.service";
import { mapGetters } from "vuex";
export default {
  name: "Login",
  props: [],
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
      form: {
        username: null,
        password: null,
        terms: false,
      },
      error: {
        username: null,
        password: null,
      },
      disableLogin: false,
    };
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
  },
  methods: {
    doLogin() {
      if (this.disableLogin) return;

      this.disableLogin = true;
      AuthService.login({
        username: this.form.username,
        password: this.form.password,
      })
        .then((resp) => {
          this.$store.dispatch("user/loginUser", resp);

          this.$noty.success("Bem-vindo à myarena!");

          this.fetchUserInfo();
          this.fetchCycles();
        })
        .catch((err) => {
          this.$noty.error(err.message);
          this.disableLogin = false;
        })
    },
    fetchUserInfo() {
      UserService.getUserProfile()
        .then((resp) => {
          this.$store.dispatch("user/updateUserProfile", resp);
          if (!this.userProfile.accepted_terms) {
            this.$root.handleInternalNavigationTo("TermsAndConditions");
          }
          else if (this.userProfile.first_login) {
            this.$root.handleInternalNavigationTo("RedefinePasswordFirstLogin");
          }
          else
            this.$root.handleInternalNavigationTo("Dashboard");
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
    },
    fetchCycles() {
      UserService.getCyclesList({
        last_filled_cycle: 1,
      })
        .then((resp) => {
          this.$store.dispatch("user/updateCyclesList", resp);
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
    },
  },
};
</script>

<style scoped lang="scss">
.page-container {
  max-width: 22.25rem;
  margin: 0 auto;
  width: auto;
  flex-grow: 1;
  text-align: center;
}

.logo {
  width: 5.9625rem;
  height: 7.125rem;
}

.reset-link {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
}

.invalid-feedback {
  margin-bottom: 0.9375rem;
  font-size: 1.125rem;
  color: #dc3545;
}
</style>
