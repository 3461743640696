<template>
  <div class="redefine-password-screen">
    <div class="is-flex is-justify-content-center">
      <div class="my-6 page-container">
        <img src="../../assets/images/logo_icon.svg" style="margin-bottom: 4.375rem" alt="" />
        <!-- <img src="../../assets/images/xmas_logo.png" style="margin-bottom: 4.375rem" alt="" /> -->
        <h2 class="is-size-3 has-text-white has-text-weight-semibold">Definir Password</h2>
        <h3 class="has-text-white is-size-5 mb-6">Insira uma nova password</h3>

        <form @submit.prevent>
          <div class="control">
            <input
              v-model="form.password"
              @keyup="error.password = null"
              type="password"
              class="main-input mb-4 input-shadow"
              :class="{ 'input-shadow-error': error.password != null }"
              placeholder="Nova password"
              required
            />
          </div>
          <div class="control">
            <input
              v-model="form.confirm_password"
              @keyup="error.password = null"
              type="password"
              class="main-input mb-4 input-shadow"
              :class="{ 'input-shadow-error': error.password != null }"
              placeholder="Confirmar password"
              required
            />
          </div>
          <div class="invalid-feedback" v-show="error.password">
            {{ error.password }}
          </div>
          <div class="field">
            <button @click="validateInput" class="has-text-white main-btn mb-5">Atualizar</button>
          </div>
        </form>
        <router-link v-if="token" to="/login" class="reset-link">Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import { mapGetters } from "vuex";
export default {
  name: "RedefinePassword",
  props: [],
  data() {
    return {
      form: {
        password: null,
        confirm_password: null,
      },
      error: {
        password: null,
      },
    };
  },
  methods: {
    validateInput() {
      if (!this.form.password || !this.form.confirm_password) {
        this.error.password = "Preencha todos os campos";
        return;
      }

      if (this.form.password != this.form.confirm_password) {
        this.error.password = "As passwords não são iguais";
        return;
      }

      if (this.form.password.length < 6) {
        this.error.password = "A password tem de ter pelo menos 6 caracteres.";
        return;
      }

      if (this.token) {
        this.redefinePassword();
        return;
      }

      this.changePassword();
    },
    redefinePassword() {
      AuthService.redefinePassword(this.form.password, this.form.confirm_password, this.token)
        .then(() => {
          this.$root.handleInternalNavigationTo("Login");
        })
        .catch((e) => {
          if (!e.message) {
            this.error.password = "Erro ao alterar password";
            this.$noty.error(this.error.password);
            return;
          }

          this.error.password = e.message;
          this.$noty.error(this.error.password);
        });
    },
    changePassword() {
      AuthService.changePassword(this.form.password, this.form.confirm_password, this.authUser.token)
        .then(() => {
          this.$root.handleInternalNavigationTo("Onboarding");
        })
        .catch((e) => {
          if (!e.message) {
            this.error.password = "Erro ao alterar password";
            this.$noty.error(this.error.password);
            return;
          }

          this.error.password = e.message;
          this.$noty.error(this.error.password);
        });
    },
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    ...mapGetters("user", ["authUser"]),
  },
};
</script>

<style scoped>
.page-container {
  max-width: 22.25rem;
  margin: 0 auto;
  width: auto;
  flex-grow: 1;
  text-align: center;
}

.reset-link {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
}

.invalid-feedback {
  margin-bottom: 0.9375rem;
  color: #dc3545;
  text-align: left;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.input-shadow {
  box-shadow: 0.25rem 0.25rem 0rem #1eb28d;
}
.input-shadow-error {
  box-shadow: 0.25rem 0.25rem 0rem #dc3545;
}
</style>
