<template>
  <div class="is-flex is-justify-content-center">
    <div class="my-6 page-container">
      <img
        src="../../assets/images/logo_icon.svg"
        style="margin-bottom: 70px"
        alt=""
      />
      <!-- <img
        src="../../assets/images/xmas_logo.png"
        style="margin-bottom: 70px"
        alt=""
      /> -->
      <h2 class="is-size-3 has-text-white has-text-weight-semibold">
        Welcome to myarena
      </h2>
      <h3 class="has-text-white is-size-5 mb-6">Impersonating</h3>

      <div class="loader" style="width: 3em; height: 3em; margin: 0 auto">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import UserService from "@/services/user.service";
import { mapGetters } from "vuex";
export default {
  name: "Login",
  props: [],
  computed: {
    ...mapGetters("user", ["userProfile"]),
    admin() {
      return this.$route.params.admin;
    },
    username() {
      return this.$route.params.username;
    },
    password() {
      return this.$route.params.password;
    },
    ...mapGetters("user", ["authUser"]),
  },
  mounted() {
    this.doImpersonate();
  },
  methods: {
    doImpersonate() {
      if (this.disableLogin) return;

      if (this.admin && this.username && this.password) {
        this.disableLogin = true;
        AuthService.impersonate(this.admin, this.username, this.password)
          .then((resp) => {
            this.$store.dispatch("user/loginUser", resp.user);

            this.$noty.success("Bem-vindo à myarena!");

            this.fetchUserInfo();
            this.fetchCycles();
          })
          .catch((err) => {
            this.$noty.error(err.message);
            this.disableLogin = false;
          })
      } else {
        this.$noty.error("Missing parameters");
      }
    },
    fetchUserInfo() {
      UserService.getUserProfile()
        .then((resp) => {
          this.$store.dispatch("user/updateUserProfile", resp);
          if (this.userProfile.first_login) {
            // this.$root.handleInternalNavigationTo("RedefinePasswordFirstLogin");
          } else {
            this.$root.handleInternalNavigationTo("Dashboard");
          }
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
    },
    fetchCycles() {
      UserService.getCyclesList({
        last_filled_cycle: 1,
      })
        .then((resp) => {
          this.$store.dispatch("user/updateCyclesList", resp);
        })
        .catch((err) => {
          this.$noty.error(err.message);
        })
    },
  },
};
</script>

<style scoped>
.page-container {
  max-width: 356px;
  margin: 0 auto;
  width: auto;
  flex-grow: 1;
  text-align: center;
}

.reset-link {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.invalid-feedback {
  margin-bottom: 15px;
  font-size: 18px;
  color: #dc3545;
}
</style>
