<template>
  <div class="recover-password-screen">
    <div class="is-flex is-justify-content-center">
      <div class="my-6 page-container">
        <img src="../../assets/images/logo_icon.svg" style="margin-bottom: 4.375rem" alt="" />
        <!-- <img src="../../assets/images/xmas_logo.png" style="margin-bottom: 4.375rem" alt="" /> -->
        <h2 class="is-size-3 has-text-white has-text-weight-semibold">Recuperar Password</h2>
        <h3 class="has-text-white is-size-5 mb-6">Insira o seu nome de utilizador</h3>

        <form v-if="!requestSent" @submit.prevent>
          <div class="control">
            <input
              v-model="username"
              @keyup="error.username = null"
              type="text"
              class="main-input mb-4 input-shadow"
              :class="{ 'input-shadow-error': error.username != null }"
              placeholder="Nome de Utilizador"
              required
            />
            <div class="invalid-feedback" v-show="error.username">
              {{ error.username }}
            </div>
          </div>
          <div class="field">
            <button @click="recoverPassword" class="has-text-white main-btn mb-5">Validar</button>
          </div>
        </form>

        <div v-else class="confirm-email">Foi lhe enviado um email de confirmação.</div>

        <router-link to="/login" class="reset-link">Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "RecoverPassword",
  props: [],
  data() {
    return {
      username: null,
      error: {
        username: null,
      },
      requestSent: false,
    };
  },
  methods: {
    recoverPassword() {
      if (!this.username) {
        this.error.username = "Campo obrigatório";
        return;
      }

      AuthService.recoverPassword(this.username)
        .then(() => {
          this.requestSent = true;
        })
        .catch((e) => {
          this.error.username = "Erro ao enviar email de confirmação.";
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.control {
  margin-bottom: 1.5rem;
}

.page-container {
  max-width: 22.25rem;
  margin: 0 auto;
  width: auto;
  flex-grow: 1;
  text-align: center;
}

.reset-link {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
}

.invalid-feedback {
  margin-bottom: 0.9375rem;
  color: #dc3545;
  text-align: left;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.input-shadow {
  box-shadow: 0.25rem 0.25rem 0rem $main-color;
}

.input-shadow-error {
  box-shadow: 0.25rem 0.25rem 0rem #dc3545;
}

.confirm-email {
  color: white;
  font-size: 2rem;
  margin-bottom: 2rem;
}
</style>
